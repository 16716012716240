<template>
    <div>
        <h1 class="text-2xl font-medium mb-4"> Manager Details </h1>
        <div class="flex flex-col space-y-4 w-full sm:w-1/2 lg:w-4/12">
            <div>
                <label> is KAM (Key Account Manager) </label>
                <vs-switch v-model="isKAM" />
            </div>
            <div>
                <label> Account Mananger Name </label>
                <vs-input :success="!errors.first('name')" :danger="errors.first('name') ? true : false"
                    :danger-text="errors.first('name')" data-vv-scope="info" val-icon-success="done"
                    val-icon-danger="error" data-vv-as="name" name="name" class="w-full" v-model="name"
                    v-validate="'required'" />
            </div>
            <div>
                <label> Phone Number </label>
                <vs-input :success="!errors.first('phone')" :danger="errors.first('phone') ? true : false"
                    :danger-text="errors.first('phone')" data-vv-scope="info" val-icon-success="done"
                    val-icon-danger="error" data-vv-as="phone" name="phone" class="w-full" v-model="phone"
                    />
            </div>
            <div>
                <label> Email </label>
                <vs-input :success="!errors.first('email')" :danger="errors.first('email') ? true : false"
                    :danger-text="errors.first('email')" data-vv-scope="info" val-icon-success="done"
                    val-icon-danger="error" data-vv-as="email" name="email" class="w-full" v-model="email"
                    v-validate="'required'" />
            </div>
        </div>
        <vs-button :disabled="!validateForm" type="filled" @click="update" class="px-6 mt-2">Update</vs-button>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: 'AccountManagerDetails',
    components: {},
    data() {
        return {
            name: '',
            isKAM: false,
            phone: null,
            email: null,
            managerId: '',
        };
    },
    computed: {
        validateForm() {
            return !this.errors.any();
        },
    },
    methods: {
        ...mapActions("appUser", ["fetchAccountManagerDetails", "updateAccountManagerDetails"]),

        async getManager(id) {
            this.fetchAccountManagerDetails(id).then(res => {
                this.name = res[0].name
                this.isKAM = res[0].isKAM
                this.phone = res[0].phone
                this.email = res[0].email
            })
        },

        async update() {
            let isValid = await this.$validator.validateAll("info");

            if (isValid) {
                const data = {
                    id: this.managerId,
                    name: this.name,
                    isKAM: this.isKAM,
                    phone: this.phone,
                    email: this.email,
                }
                this.$vs.loading();
                this.updateAccountManagerDetails(data)
                    .then((result) => {
                        if(result.message === "Success") {
                            this.$vs.notify({
                                title: "Success",
                                text: "Account manager updated successfully.",
                                color: "success",
                            });
                            this.$router.back()
                        } else {
                            this.$vs.notify({
                                title: "Error",
                                text: result.message,
                                color: "danger",
                            });
                        }
                    })
                    .catch((err) => {
                        this.$vs.notify({
                            title: "Error",
                            text: err.data.message,
                            color: "danger",
                        });
                    })
                    .finally(() => {
                        this.$vs.loading.close();
                    })
            
            }
        },
    },
    async created() {
      this.managerId = this.$route.params.managerId;
      this.managerId && this.getManager(this.managerId)
    }
}
</script>